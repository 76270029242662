import {
  DashboardOutlined,
  UserOutlined,
  GatewayOutlined,
  PhoneOutlined,
  CalendarOutlined,
  GiftOutlined,
  TeamOutlined,
  TranslationOutlined,
  ControlOutlined,
  SendOutlined,
  GlobalOutlined,
  FileOutlined,
  KeyOutlined,
  MailOutlined,
  SolutionOutlined,
  FolderOpenOutlined,
  ContainerOutlined,
  MailFilled,

} from "@ant-design/icons";
import {APP_PREFIX_PATH} from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "dashboards",
    path: `${APP_PREFIX_PATH}/dashboards`,
    title: "sidenav.dashboard",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "dashboards-default",
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        title: "sidenav.dashboard.default",
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "dashboards-users",
        path: `${APP_PREFIX_PATH}/dashboards/users`,
        title: "sidenav.dashboard.users",
        icon: UserOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "dashboards-admins",
        path: `${APP_PREFIX_PATH}/dashboards/admins`,
        title: "sidenav.dashboard.admins",
        icon: TeamOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "dashboards-admins",
            path: `${APP_PREFIX_PATH}/dashboards/admins`,
            title: "sidenav.dashboard.admins",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "dashboards-contacts",
        path: `${APP_PREFIX_PATH}/dashboards/contacts`,
        title: "sidenav.dashboard.contacts",
        icon: PhoneOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "dashboards-report-problem",
        path: `${APP_PREFIX_PATH}/dashboards/report_problem`,
        title: "sidenav.dashboard.report_problem",
        icon: FileOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const settingsNavTree = [
  {
    key: "settings",
    path: `${APP_PREFIX_PATH}/settings`,
    title: "sidenav.global_settings",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "settings-payment_gateway",
        path: `${APP_PREFIX_PATH}/settings/payment_gateway`,
        title: "sidenav.global_settings.payment_gateway",
        icon: GatewayOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "settings-fcm_credentials",
        path: `${APP_PREFIX_PATH}/settings/fcm_credentials`,
        title: "sidenav.global_settings.fcm_credentials",
        icon: KeyOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "settings-areas",
        path: `${APP_PREFIX_PATH}/settings/areas`,
        title: "sidenav.global_settings.areas",
        icon: GlobalOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "settings-languages_admin",
        path: `${APP_PREFIX_PATH}/settings/languages`,
        title: "sidenav.global_settings.admin_languages",
        icon: TranslationOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "settings-global-languages",
            path: `${APP_PREFIX_PATH}/settings/languages/global_languages`,
            title: "sidenav.global_settings.global_languages",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "settings-global-languages-screen",
            path: `${APP_PREFIX_PATH}/settings/languages/language_screens`,
            title: "sidenav.global_settings.language_screens",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "settings-global-languages-strings",
            path: `${APP_PREFIX_PATH}/settings/languages/language_strings`,
            title: "sidenav.global_settings.language_strings",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "settings-emails_admin",
        path: `${APP_PREFIX_PATH}/settings/smtp_credentials`,
        title: "sidenav.global_settings.admin_email_settings",
        icon: MailOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "settings-email-settings",
            path: `${APP_PREFIX_PATH}/settings/smtp_credentials`,
            title: "sidenav.global_settings.smtp_credentials",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "settings-admin_app",
        path: `${APP_PREFIX_PATH}/settings/admin_app`,
        title: "sidenav.global_settings.admin_app",
        icon: KeyOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const appSettingsNavTree = [
  {
    key: "app_settings",
    path: `${APP_PREFIX_PATH}/settings`,
    title: "sidenav.app_settings",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "settings-social_links",
        path: `${APP_PREFIX_PATH}/settings/social_links`,
        title: "sidenav.app_settings.social_links",
        icon: GlobalOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "settings-app_wallet",
        path: `${APP_PREFIX_PATH}/settings/app-wallet`,
        title: "sidenav.app_settings.app_wallet",
        icon: GlobalOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "settings-app_controls",
        path: `${APP_PREFIX_PATH}/settings/app_controls`,
        title: "sidenav.app_settings.app_controls",
        icon: ControlOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "settings-fcm_send",
        path: `${APP_PREFIX_PATH}/settings/fcm_send`,
        title: "sidenav.app_settings.fcm_send",
        icon: SendOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },{
    key: "sidenav.app_settings.email_content",
    path: `${APP_PREFIX_PATH}/settings/email_content`,
    title: "sidenav.app_settings.email_content",
    icon: MailFilled,
    breadcrumb: false,
    submenu: []
  }
];

const generalSettings = [
  {
    key: "general_settings",
    path: `${APP_PREFIX_PATH}/apps`,
    title: "sidenav.general_settings",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "general_settings.experience_types",
        path: `${APP_PREFIX_PATH}/cms/experience_types`,
        title: "general_settings.experience_types",
        icon: GlobalOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "general_settings.experience_items",
        path: `${APP_PREFIX_PATH}/cms/experience_items`,
        title: "general_settings.experience_items",
        icon: GlobalOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "general_settings.experiences",
        path: `${APP_PREFIX_PATH}/cms/experiences`,
        title: "general_settings.experiences",
        icon: ContainerOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "general_settings.experiences_collection",
        path: `${APP_PREFIX_PATH}/cms/experience_collection`,
        title: "general_settings.experiences_collection",
        icon: FolderOpenOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "general_settings.spaces",
        path: `${APP_PREFIX_PATH}/cms/spaces`,
        title: "general_settings.spaces",
        icon: ContainerOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "general_settings.spaces_collection",
        path: `${APP_PREFIX_PATH}/cms/spaces_collection`,
        title: "general_settings.spaces_collection",
        icon: FolderOpenOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "general_settings.featured_hosts",
        path: `${APP_PREFIX_PATH}/cms/hosts/collection`,
        title: "general_settings.featured_hosts",
        icon: FolderOpenOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "general_settings.app_hosts",
        path: `${APP_PREFIX_PATH}/cms/hosts`,
        title: "general_settings.app_hosts",
        icon: SolutionOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "general_settings.app_bookings",
        path: `${APP_PREFIX_PATH}/cms/bookings`,
        title: "general_settings.app_bookings",
        icon: CalendarOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "general_settings.app_dicounts",
        path: `${APP_PREFIX_PATH}/cms/discounts`,
        title: "general_settings.app_dicounts",
        icon: GiftOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [
  ...dashBoardNavTree,
  ...generalSettings,
  ...appSettingsNavTree,
  ...settingsNavTree,
];

export default navigationConfig;
